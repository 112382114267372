@media (min-width: 993px) {
    .row-posts {
        clear: both;
    }
}

@media (min-width: 992px) {
    .modal-sm {
        max-width: 840px !important;
    }
    .candidate-filters {
        margin-top: 66px;
    }
    .hirer p {
        line-height: 27.9px;
    }

    .max-width-50-pc-md-up {
        max-width: 50%;
    }
}

@media (min-width: 768px) {
    .subnav.nav.navbar-nav li:first-child a {
        padding-left: 0;
    }
    .modal-sm {
        max-width: 600px !important;
    }

    body{
        header.main-header,
        header.main-header .logo,
        header.main-header .navbar{
            float: left;
            .nav>li>a{
                padding: 15px 20px;
            }
        }

        header.main-header{
            .logo{
                width: 20%;
            }
        }
    }
    body.schedule-meeting
    {
        .calendly-inline-widget
        {
            margin-top: 130px;
        }
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    
    /*Blog Adjustments*/

        .row-posts {
            clear: none;
        }
        .listing-clearfix {
            clear: both;
        }
}

@media (max-width: 1280px){
    body{
        .banner{
            .arrow-cta{
                display: none;
            }
        }
    }
}

@media (max-width: 1199px) {
    .container {
        width: 100% !important;
    }
}

@media (max-width: 1024px) {
    .video-block {
        padding-bottom: 50%;
    }
    .register .banner{
        background-size: cover;
    }
    .banner h2
    {
        margin: 20px 0;
    }
    .text-wrapper
    {
        p
        {
            font-size: 11px;
        }
    }
    .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
      margin-top: 50px;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }


}

@media (min-width: 768px) and (max-width: 1024px)
{
    .tags .label-primary
    {
        font-size: 9px;
        width: 100%;
        display: block;
        margin-bottom: 5px;
        word-break: break-word;
        white-space: normal;
  }
}

@media (max-width: 991px){

      // Backend Top Nav

        body{
            .top-nav{
                ul{
                    padding: 0;
                    text-align: center;
                    li{
                        margin-right: 2px;
                        padding: 4px 6px;
                        .fa{
                            font-size: 12px;
                        }
                    }
                }
            }
            .full-width-three-col .how-works-item{
                margin-bottom: 30px;
            }
        }

      // How it Works

        .work-step{
            padding-left: 15px;
            padding-right: 15px;
            br{
                display: none;
            }
            p{
                font-size: 14px;
                line-height: 18px;
            }
        }
        .parallelogram{
            width: 91%;
        }
        .cookie-background{
            width: 50%;
        }
}

@media (max-width: 767px) {

    // Header

        body{
            .main-header{
                position: relative;
                min-height: 100px;
                box-shadow: none;
                .header-cta{
                    position: absolute;
                    bottom: 20px;
                    right: 115px;
                }
                .navbar-toggle{
                    margin-top: 35px;
                }
                .navbar{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 58px;
                    width: 100%;
                    background: #fff;
                    z-index: -9999;
                    #myNavbar {
                        width: 100%;
                        li {
                            margin: 0;
                            padding: 0;
                            .cta.red{
                                color: #fff;
                                border: none;
                                background: $red_two;
                            }
                            a {
                                padding: 15px;
                                border-bottom: 1px solid #ccc;
                            }
                        }
                    }
                }
            }
        }

    // Banner

        body{
            .banner{
                .carousel{
                    min-height: auto;
                    .item{
                        min-height: 200px;
                        padding-top: 0;
                    }
                }
            }
        }

    // Footer

        body{

            footer{
                text-align: center;
                 ul.footer-social{
                    text-align: center;
                 }
                 .cta{
                    margin-bottom: 20px;
                    padding-left: 15px;
                    width: 158px;
                    margin-right: 15px;
                 }
            }
        }

    // Banner

        body{
            .banner{
                margin-top: 0;
                background-position: 82% -16%;
                min-height: auto;
                max-height: 480px; 
                .arrow-cta{
                    display: none;
                }
                h2{
                    font-size: 21px;
                    line-height: 30px;
                }
                .carousel{
                    background: rgba(0,0,0,0.3);
                    padding: 30% 0 30px 0;
                }
                .cta{
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            .candidates{
                .banner{
                    padding-top: 30%;
                    h2{
                        color: #2d2d2d;
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
            .banner.blog-banner,
            .banner.contact-banner{
                    padding-top: 20%;
                    min-height: 50vh;
                    margin-top: 0;
                    h2{
                        color: #2d2d2d;
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
        }

    

    // Cookie Bar

        .cookie-background{
            width: 80%;
        }

    // How it Works

        .work-step{
            padding-bottom: 10px;
            h3{
                margin: 10px 0 5px 0;
                font-size: 18px;
            }
        }

        .central-text-block{
                h2{
                    padding: 20px 30px;
                    margin: 0;
                    font-size: 21px;
                }
        }

        .full-width-three-col{
            padding: 0px 0 60px 0;
            .how-works-item
            {
                width: 90%;
                margin-left: 5%;
                margin-right: auto;
            }
            &:not(.how-works-landing)
            {
                .how-works-item:first-child
                {
                    *[class*="fa"]
                    {
                        margin-top: 20px;
                    }
                }
            }
        }

        .how-it-works-landing{
            h2{
                font-size: 40px;
            }
        }

        .parallelogram{
            width: 93%;
            height: 25px;
        }

    #cookie-bar {
        padding-top: 54px;
        line-height: 16px;
    }

    // Blog

        body.blog{
            padding: 0;
        }

    /*BS3 Adjustments*/

        .col-sm-m-top-30 {
            margin-top: 30px;
        }

        .col-sm-block {
            width: 100%;
        }

        body {
            .main-header{
                .logo{
                    float: left;
                    padding: 15px;
                }
            }
        }

    // Home Blog

        .image-right-block{
            .handshake{
                margin-right: 15px;
                padding-bottom: 15px;
                margin-top: 350px;
            }
        }

    // Header

        .navigation .navbar-form {
          display: none;
        }

        #myNavbar .navbar-nav {
            margin: 0;
            padding: 0;
            background: #fff;
        }



        .navbar-toggle {
            display: block;
            z-index: 1000;
            margin-top: 50px;
        }

        // Header

            .main-header{
                .no-padding-mobile{
                    padding: 0;
                }
                .logo{
                    padding-left: 15px;
                    img{
                        width: 65%;
                    }
                }
            }

        .brand-sprite.top-logo {
            margin-top: 75px;
            position: relative;
        }

        .profile-steps a {
            white-space: nowrap;
        }

    // Footer

         .footer {
            text-align: center;
        }

        .footer-items {
            margin: 0 auto;
            display: inline-block;
        }

        .footer-items li {
            text-align: left;
        }

        .list-items {
            margin: 0 auto;
            display: inline-block;
        }

        .list-items li {
            text-align: left;
        }

        .col-sm-cen, .col-sm-cen p {
            text-align: center !important
        }

        .banner
        {
            h2
            {
                font-size: 20px;
                padding: 20px;
                background: rgba($white, .4);
            }
        }

        .calendly-inline-widget
        {
            overflow-y: unset !important;
        }

        .how-works-icon
        {
            .img-responsive
            {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        // Brand Sidebar & Main View

        .backend
        {
            .brand-sidebar
            {
                width: 160px;
            }
            .main-view
            {
                margin-left: 160px;
            }
        }

        // Video block

        .home
        {
            .central-text-block
            {
                h2
                {
                    font-size: 15px;
                }
            }
        }

        .register
        {
            .banner
            {
                h2
                {
                    margin-top: 25%;
                }
            }
        }

        ul.sidebar-nav
        {
            overflow-y: scroll;
            max-height: 450px;
        }

    .text-wrapper
    {
        margin-top: 20px;
        p
        {
            font-size: 15px;
            &:not(.text-center)
            {
                max-width: 65%;
                margin: 0 auto;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

    .image-right-block
    {
        .handshake
        {
            margin-top: 350px;
        }
    }

    .max-width-50-pc-sm {
        max-width: 50%;
    }
}

@media (min-width: 580px) and (max-width: 767px)
{
    .image-right-block
    {
        .handshake
        {
            margin-top: 280px; 
        }
    }

}

@media (max-width: 667px) {

    .bootstrap-select.btn-group .dropdown-menu {
        max-width: 100%;
    }

    .alt-modal.clone-select-modal .modal-content {
        max-width: 100%;
    }

    .alt-modal.clone-select-modal .modal-body {
        padding: 5px;
    }

    .bootstrap-select.btn-group .dropdown-menu li a {
        font-size: 12px;
        padding: 5px;
    }
    .fs-50 {
        font-size: 36px;
    }
    .navbar {
        min-height: 0;
    }
    .label-list li {
       width: 100%;
    }
    .bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 500px) {

    /* Center Box Responsive */

        .center-box-grey p {
            width: 90%
        }

        .center-box-grey .form-block {
            margin-left: 15px;
            margin-right: 15px
        }
}


@media (max-width: 480px){

    .parallelogram
    {
        width:92.9%;
        margin-left: 12px;
    }

    .image-right-block
    {
        .handshake
        {
            margin-top: 380px;
        }
    }

    .video-block {
        min-height: 200px;
    }

    .membershipTable {
        td, th {
            padding: 10px 5px;
            text-align: center;
        }
    }
 
}

