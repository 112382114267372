// Variables

  @import 'variables';

// Fonts

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Regular.eot');
    src: url('/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/OpenSans-Regular.woff') format('woff'),
        url('/fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-SemiBold.eot');
    src: url('/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/OpenSans-SemiBold.woff') format('woff'),
        url('/fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Bold.eot');
    src: url('/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/OpenSans-Bold.woff') format('woff'),
        url('/fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GothamBoldRegular';
    src: url('/fonts/gotham-bold-webfont.eot');
    src: url('/fonts/gotham-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gotham-bold-webfont.woff') format('woff'),
         url('/fonts/gotham-bold-webfont.ttf') format('truetype'),
         url('/fonts/gotham-bold-webfont.svg#GothamBoldRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'GothamBookRegular';
    src: url('/fonts/gotham-book-webfont.eot');
    src: url('/fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gotham-book-webfont.woff') format('woff'),
         url('/fonts/gotham-book-webfont.ttf') format('truetype'),
         url('/fonts/gotham-book-webfont.svg#GothamBookRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'GothamMediumRegular';
    src: url('/fonts/gotham-medium-webfont.eot');
    src: url('/fonts/gotham-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gotham-medium-webfont.woff') format('woff'),
         url('/fonts/gotham-medium-webfont.ttf') format('truetype'),
         url('/fonts/gotham-medium-webfont.svg#GothamMediumRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'GothamBlackRegular';
    src: url('/fonts/gotham-black-webfont.eot');
    src: url('/fonts/gotham-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gotham-black-webfont.woff') format('woff'),
         url('/fonts/gotham-black-webfont.ttf') format('truetype'),
         url('/fonts/gotham-black-webfont.svg#GothamBlackRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'GothamLightRegular';
    src: url('/fonts/gotham-light-webfont.eot');
    src: url('/fonts/gotham-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/gotham-light-webfont.woff') format('woff'),
         url('/fonts/gotham-light-webfont.ttf') format('truetype'),
         url('/fonts/gotham-light-webfont.svg#GothamLightRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
body,p,a,input,.f-book{
    font-family: 'GothamBookRegular';
}
h1,h2,h3,h4,h5{
    font-family: 'GothamMediumRegular';
}
.fs-10{font-size:10px;}.fs-12{font-size:12px;}.fs-18{font-size:18px!important;}.fs-16{font-size:16px!important;}
.ts-24{font-size:24px}
.nm{margin:0;}
strong, .f-strong{
    font-family:'GothamBoldRegular'!important;
}
.footer-items strong{
    font-family:'GothamBookRegular'!important;
}
.book-bold{
        font-family:'GothamBookRegular'!important;
font-weight:bold;
}
.f-light{
    font-family:'GothamLightRegular';
}
.text-black{color:#000!important;}
.text-right{
    text-align:right;
}
.text-center{
    text-align:center;
}
/* FormPlaceholder - not quite typography I know, maybe I'll move it later.*/
.form-control::-webkit-input-placeholder { color:#999;}
.form-control:-moz-placeholder { color:#999;}
.form-control::-moz-placeholder { color:#999;}
.form-control:-ms-input-placeholder { color:#999;}
.heading{
    color:#153661;
    font-family:'GothamBlackRegular';
    font-size:18px;
}
