// Variables

	$red:        		#b53038;
	$red-two: 			#d9343e;
	$dark-grey:  		#2d2d2d;
    $dark-grey-two:     #575654;
    $dark-grey-three:   #504c4c;
	$darker-grey: 		#404040;
	$light-grey: 		#f5f5f5;
	$light-grey-two: 	#f4f5f8;
	$grey:				#aaaaaa;
	$grey-two:			#a9a9a9;
	$grey-three:		#5c5b5b;
	$light-green:		#34be78;
	$twitter-blue: 		#4dacd8;
	$linkedin-blue: 	#205392;
	$youtube-red:       #ff0000;
    $border-grey:       #ccc;
    $white:             #fff;
    $table-grey:        rgba(60, 60, 60, 0.3);
